import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useEditor, useNode } from "@craftjs/core";
import { Button, Col, Image, InputNumber, message, Modal, Skeleton, Space, Switch, Typography, Upload } from "antd";
import { Form, FormContext, Input, Select } from "components/Form";
import { handleUploadFile } from "parse-api/file";
import React, { useState } from "react";
import IntlMessages from "util/IntlMessages";
import {
  colSls, convertRules, convertStyleStr, deleteButton, EditorCollector, getId, NodeCollector, registerComponent, SfLayoutSetting, SfPanelContext, shouldUpdate
} from "./common";
import { push } from 'connected-react-router';
import { useDispatch } from "react-redux";
import { AccountStore } from "constants/Account";
import { log, convertBytesToFileSize } from "util/algorithm";
import { systemSignal } from "../../../../util/signal";
import {
  EyeOutlined, DownloadOutlined, DeleteOutlined, PaperClipOutlined
} from '@ant-design/icons';
import ReactPlayer from 'react-player';
import moment from "moment";

const SfUploadSetting = () => {
  return (
    <>
      <Form.Item name="title" label="title">
        <Input className="item-property" />
      </Form.Item>
      <Form.Item name="listType" label="list type">
        <Select className="item-property">
          <Select.Option value="text">text</Select.Option>
          <Select.Option value="picture">picture</Select.Option>
          <Select.Option value="picture-card">picture-card</Select.Option>
          <Select.Option value="table">table</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="customItem" label="custom item" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="directory" label="directory" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="multiple" label="multiple" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="preview" label="preview" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="download" label="download" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="remove" label="remove" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="maxCount" label="max count">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="accept" label="accept">
        <Space>
          <Form.Item name="accept" label="accept" noStyle={true}>
            <Input className="item-property" />
          </Form.Item>
          <Typography.Link
            target="_blank" rel="noopener noreferrer"
            href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept">Need Help?</Typography.Link>
        </Space>
      </Form.Item>
      <Form.Item name="acceptFileTypes" label="file types">
        <Select className="item-property" mode="tags"/>
      </Form.Item>
      <Form.Item name="acceptFileExtensions" label="file exts">
        <Select className="item-property" mode="tags"/>
      </Form.Item>
      <Form.Item name="defaultSharing" label="default sharing">
        <Select className="item-property">
          <Select.Option value="public-image-protected-file">public-image-protected-file</Select.Option>
          <Select.Option value="public">public</Select.Option>
          <Select.Option value="protected">protected</Select.Option>
          <Select.Option value="private">private</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="disabled" label="disabled" valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInTable" label="hide col." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="hideInMobile" label="hide mob." valuePropName="checked">
        <Switch className="item-property" />
      </Form.Item>
      <Form.Item name="tableColWidth" label="col width">
        <InputNumber min="1" className="item-property" />
      </Form.Item>
      <Form.Item name="tableColTitle" label="col title">
        <Input className="item-property" />
      </Form.Item>
      <SfLayoutSetting />
    </>
  )
}

export const SfUpload = ({ ...props }) => {
  const { connectors: { connect, drag }, selected, style } = useNode(NodeCollector);
  const { actions, selectedNode } = useEditor(EditorCollector);
  const dbtn = deleteButton(selected, selectedNode, actions)
  return (
    <SfpUpload doRef={ref => connect(drag(ref))} style={style} dbtn={dbtn} {...props} />
  )
}

const getUploadButton = (listType, disabled) => {
  if (listType === "picture-card") {
    return (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}><IntlMessages id="system.form.library.upload" text="Upload" /></div>
      </div>
    );
  } else {
    return (
      <Button icon={<UploadOutlined />} disabled={disabled}><IntlMessages id="system.form.library.upload" text="Upload" /></Button>
    );
  }
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const onRemove = async (file) => {
  return true;
}

export const MyUpload = ({
  preview, ...otherProps
}) => {
  const { headless } = systemSignal;
  const dispatch = useDispatch();
  const [previewState, setPreviewState] = useState({ image: null, visible: false, title: null });
  const openPreview = async file => {
    if (file.type && file.type.indexOf("image") !== -1) {
      console.log("openPreview() file", file)
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }

      setPreviewState({
        image: file.url || file.preview,
        visible: true,
        title: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
      });
    } else {
      message.error(<IntlMessages id="system.form.upload_preview_error" text="We are not able to preview this file!" />);
    }
  };
  const closePreview = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setPreviewState({ visible: false, image: null });
  }
  const onDownload = (file) => {
    if (file.url && file.url.startsWith(AccountStore.PARSE_HOST_URL+'/files/')) {
      if (headless) {
        window.open(file.url.replace(AccountStore.PARSE_HOST_URL+'/files/', '/parse/files/')+'?headless=true');
      } else {
        dispatch(push(file.url.replace(AccountStore.PARSE_HOST_URL+'/files/', '/parse/files/')))
      }
    } else {
      log("file is not host in this server", file);
    }
  }
  return (
    <Upload {...otherProps}
      onPreview={preview ? openPreview : null} onRemove={onRemove}
      onDownload={onDownload}
      >
      {getUploadButton(otherProps.listType, otherProps.disabled)}
      <Modal
        visible={previewState.visible}
        title={previewState.title}
        footer={null}
        onCancel={closePreview}
      >
        <Image alt="preview" style={{ width: '100%' }}
          preview={false} placeholder={true}
          src={previewState.image} key={previewState.image} />
      </Modal>
    </Upload>
  )
}

export const SfpUpload = ({
  doRef, form, condistyles, className, style, dbtn, hideInTable, tableColWidth, tableColTitle,
  itemKey, title, labelAlign, labelColStr, rules, volitate, skipcopy, defaultSharing,customItem,
  inputref, styleStr, preview,download,remove, disabled, children, accept, acceptFileTypes, acceptFileExtensions, ...otherProps }) => {
  const [api, contextHolder] = message.useMessage();
  const [previewState, setPreviewState] = useState({ url: null, visible: false, title: null });
  const [localFileList, setLocalFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { headless } = systemSignal;
  const sls = convertStyleStr(styleStr);
  const lcs = convertStyleStr(labelColStr);
  const [fxr, setFxr] = useState({});
  const dispatch = useDispatch();
  const newRules = convertRules(rules);
  const openPreview = async file => {
    if (file.type && (file.type.indexOf("image") !== -1 || file.type.indexOf("video") !== -1) ) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewState({
        url: file.url || file.preview || file.thumbUrl,
        visible: true,
        title: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        isVideo: file.type.indexOf("video") !== -1 ? true : false
      });
    } else {
      api.error(<IntlMessages id="system.form.upload_preview_error" text="We are not able to preview this file!" />);
    }
    console.log("openPreview", file,previewState)

  };

  const onDownload = (file) => {
    if (file.url && file.url.startsWith(AccountStore.PARSE_HOST_URL+'/files/')) {
      if (headless) {
        window.open(file.url.replace(AccountStore.PARSE_HOST_URL+'/files/', '/parse/files/')+'?headless=true');
      } else {
        dispatch(push(file.url.replace(AccountStore.PARSE_HOST_URL+'/files/', '/parse/files/')))
      }
    } else {
      log("file is not host in this server", file);
    }
  }

  const closePreview = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setPreviewState({ visible: false, url: null });
  }

  const uploadRules =  rules ? [...rules] : [];
  uploadRules.push(
    {
      validator: () =>
      (uploading ? Promise.reject(<IntlMessages id="system.form.upload_not_complete" text="Please wait a moment..."/>) : Promise.resolve())
    }
  );

  const beforeUpload = async (form, name, file) => {
    let fileList = [];
    if (form) {
      fileList = form.getFieldValue(name);
    } else {
      fileList = [...localFileList];
    }

    let newFileList = null;
    const maxCount = otherProps.maxCount;
    if (maxCount) {
      if (maxCount > 1) {
        if (fileList.length >= maxCount) {
          api.error(<IntlMessages id="system.form.upload_limit" text="Reached max file count ({maxCount})" values={{maxCount:maxCount}} />);
        } else {
          newFileList = [...fileList, file];
        }
      } else if (maxCount === 1) {
        newFileList = [file];
      }
    } else {
      newFileList = [...fileList, file];
    }

    if (newFileList) {
      if (form) {
        form.setFields([{
          name: name,
          value: newFileList
        }]);
      } else {
        setLocalFileList(newFileList);
      }
    }
    return false;
  }

  const getValueFromEvent = (form, name, args) => {
    let fileList = []
    if (args.length > 0) {
      const event = args[0];
      if (Array.isArray(event)) {
        fileList = event;
      } else {
        fileList = event.fileList;
      }
    }
    fileList.forEach(f => {
      if (!f.status) {
        f.permission = defaultSharing;
        setUploading(true);
        handleUploadFile(f.name, f, (file, progressValue, info, parseFile, error) => {
          let changed = false;
          let completed = false;
          if (info) {
            const { type } = info;
            if (type === "upload" && progressValue !== null) {
              file.percent = progressValue * 100;
              file.status = "uploading";
              changed = true;
            }
            if (type === "download") {
              file.status = 'done';
              changed = true;
            }
          } else if (parseFile) {
            const index = fileList.indexOf(file);
            const newFile = { ...file };
            newFile.url = parseFile.url();
            newFile.fileKey = parseFile.name();
            newFile.name = file.name;
            newFile.originFileObj = undefined;
            fileList[index] = newFile;
            changed = true;
            completed = true;
          } else if (error) {
            if (React.isValidElement(error)) {
              api.error(error);
            } else {
              api.error(`${error}`);
            }
            file.status = 'error';
            changed = true;
            completed = true;
          }
          if (changed) {
            const newFileList = [...fileList];
            if (form) {
              form.setFields([{
                name: name,
                value: newFileList
              }]);
            } else {
              setLocalFileList(newFileList);
            }
            if (completed) {
              setUploading(false);
              if (form && name) {
                form.validateFields([name]);
              }
            }
          }
        }, acceptFileTypes, acceptFileExtensions);
      }
    })
    return fileList;
  }

  const getFileListCard = (originNode,file,fileList,actions) => {
    let isPicCardListType = otherProps?.listType === "picture-card";
    let isPicListType = otherProps?.listType === "picture";
    let isTableListType = otherProps?.listType === "table";
    if (file?.type
      && (file?.type.indexOf("image") !== -1 || file.type.indexOf("video") !== -1)
      && (isPicCardListType || isPicListType)) {
      let withoutVideo = file.type.indexOf("video") !== -1;
      let fileClassName = file?.status === 'error' ? 'ant-upload-list-item-error' : 'ant-upload-list-item-done';
      fileClassName += isPicCardListType ? ' ant-upload-list-item-list-type-picture-card' : ' ant-upload-list-item-list-type-picture';
      fileClassName += ' ant-upload-list-item';
      let containerClassName = isPicCardListType ? 'ant-upload-list-picture-card-container' : 'ant-upload-list-picture-container';
      return <div className={containerClassName}>
              {isPicCardListType ? <div class={fileClassName}>
                <div class="ant-upload-list-item-info">
                  <span class="ant-upload-span">
                    <a class="ant-upload-list-item-thumbnail" href={file.url} target="_blank" rel="noopener noreferrer">
                    {withoutVideo ?<video
                            preload='auto'
                            style={{ width: '100%', height: '100%', aspectRatio: '16/9', objectFit: 'cover' }}
                            src={file.url}
                          />
                          :
                          <Image alt="preview" style={{ width: '100%' }}
                            preview={false} placeholder={true}
                            src={file.thumbUrl || file.url} key={file.url} />
                        }
                    </a>
                    <a target="_blank" rel="noopener noreferrer" class="ant-upload-list-item-name" title={file.name} href={file.url} >{file.name}</a>
                  </span>
                </div>
                <span class="ant-upload-list-item-actions">
                  {preview && <EyeOutlined onClick={()=>{openPreview(file)}}/>}
                  {download && <DownloadOutlined onClick={()=>{onDownload(file)}}/>}
                  {remove && <DeleteOutlined onClick={()=>{actions.remove(file)}}/>}
                </span>
              </div>
              :
              <div class={fileClassName}>
                <div class="ant-upload-list-item-info">
                  <span class="ant-upload-span" >
                    <div class="ant-upload-list-item-thumbnail" rel="noopener noreferrer" onClick={()=>{openPreview(file)}}>
                      {withoutVideo ?<video
                          preload='auto'
                          style={{ width: '100%', height: '100%', aspectRatio: '16/9', objectFit: 'cover' }}
                          src={file.url}
                        />
                        :
                        <Image alt="preview" style={{ width: '100%' }}
                          preview={false} placeholder={true}
                          src={file.thumbUrl || file.url} key={file.url} />
                      }
                    </ div>
                    <div  rel="noopener noreferrer" class="ant-upload-list-item-name" title={file.name}  onClick={()=>{openPreview(file)}}>{file.name}</div >
                    <span class="ant-upload-list-item-card-actions picture">
                      {remove && <DeleteOutlined onClick={()=>{actions.remove(file)}}/>}
                    </span>
                  </span>
                </div>
              </div>}
            </div>
    } else if (isTableListType) {
      const size = file.size ? convertBytesToFileSize(file.size) : 'unknown';
      const lastModified = file.lastModified ? moment(file.lastModified).format('Y/M/D HH:mm:ss') : 'unknown';
      file.title = `${file.name}\n(${size})\n${lastModified}`;
      return  <div class="ant-upload-list ant-upload-list-text">
                <div class="ant-upload-list-text-container">
                  <div class="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-text">
                    <div class="ant-upload-list-item-info">
                      <span class="ant-upload-span">
                        <div class="ant-upload-text-icon"><PaperClipOutlined /></div>
                        <a target="_blank" rel="noopener noreferrer" class="ant-upload-list-item-name" title={file.title} href={file.url} >{file.name}</a>
                        <span class="ant-upload-list-item-card-actions">
                          {<DeleteOutlined onClick={()=>{actions.remove(file)}}/>}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <span>({size})</span>
                <span style={{'padding-left':'5px'}}>{lastModified}</span>
              </div>
    } else {
      return originNode
    }
  }

  if (doRef) {
    return (
      <SfPanelContext.Consumer>
        {ctx => {
          const name = ctx ? [...ctx.name, itemKey] : [itemKey];
          const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
          return (
            <Col ref={doRef} className={className} style={fxr.style || style} {...colSls(otherProps)}>
              {contextHolder}
              <Form.Item name={name} label={title}
                shouldUpdate={fx} hidden={fxr.hidden} rules={fxr.hidden || otherProps.disabled ? null : newRules}
                labelAlign={labelAlign} labelCol={lcs} valuePropName="fileList"
                getValueFromEvent={(...args) => getValueFromEvent(null, null, args)} wrap>
                  <Upload inputref={inputref} style={sls} {...otherProps}
                  //disabled={disabled || previewState.visible || uploading}
                  disabled={true}
                  beforeUpload={(file) => beforeUpload(null, null, file)}
                  onPreview={preview ? openPreview : null} onRemove={onRemove}
                  itemRender={customItem ? getFileListCard : undefined}
                  >
                  {getUploadButton(otherProps.listType, disabled)}
                  {previewState.visible &&  <Modal
                    visible={previewState.visible}
                    title={previewState.title}
                    footer={null}
                    onCancel={closePreview}
                  >
                    <Skeleton.Image />
                    {previewState?.isVideo ?
                    <ReactPlayer
                      url={previewState.url}
                      width="100%"
                      height="100%"
                      playing={previewState.visible}
                      controls={{
                          visibility: 'hidden',
                          playing: false,
                          control: true
                      }}
                      config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                      style={{ aspectRatio: '1920/1080' }}
                    />
                    :
                    <Image alt="preview"
                      preview={false} placeholder={true}
                      src={previewState.url} key={previewState.url} />
                    }
                  </Modal>}
                </Upload>
              </Form.Item>
              {dbtn}
            </Col>
          )
        }}
      </SfPanelContext.Consumer>
    );
  } else {
    return (
      <SfPanelContext.Consumer>
        {ctx => {
          const name = ctx ? [...ctx.name, itemKey] : [itemKey];
          const fx = shouldUpdate({condistyles, ctx, form, style, setFxr});
          return (
            <FormContext.Consumer>
              {(formCtx) => {
                return (
                  <Col className={className} style={fxr.style || style} {...colSls(otherProps)}>
                    {contextHolder}
                    <Form.Item name={name} label={title}
                      shouldUpdate={fx} hidden={fxr.hidden} rules={fxr.hidden || otherProps.disabled ? null : uploadRules}
                      labelAlign={labelAlign} labelCol={lcs} valuePropName="fileList"
                      getValueFromEvent={(...args) => getValueFromEvent(formCtx?.formInstance, name, args)} wrap>
                      <Upload inputref={inputref} style={sls} {...otherProps}
                        accept={accept}
                        disabled={disabled || previewState.visible || uploading}
                        beforeUpload={(file) => beforeUpload(formCtx?.formInstance, name, file)}
                        onPreview={preview ? openPreview : null} onRemove={onRemove}
                        onDownload={onDownload}
                        showUploadList={{
                          showPreviewIcon: preview,
                          showDownloadIcon: download,
                          showRemoveIcon: remove,
                        }}
                        itemRender={customItem ? getFileListCard : undefined}
                        >
                        {getUploadButton(otherProps.listType, disabled)}
                        {previewState.visible && <Modal
                          visible={previewState.visible}
                          title={previewState.title}
                          footer={null}
                          onCancel={closePreview}
                        >
                          {previewState?.isVideo ?
                          <ReactPlayer
                              url={previewState.url}
                              width="100%"
                              height="100%"
                              playing={previewState.visible}
                              controls={{
                                  visibility: 'hidden',
                                  playing: false,
                                  control: true
                              }}
                              config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                              style={{ aspectRatio: '1920/1080' }}
                          />
                          :
                          <Image alt="preview"
                            preview={false} placeholder={true}
                            src={previewState.url} key={previewState.url} />
                          }
                        </Modal>}
                      </Upload>

                    </Form.Item>
                  </Col>)
              }}
            </FormContext.Consumer>
          )
        }}
      </SfPanelContext.Consumer>
    );
  }
}

SfpUpload.render = ({ itemKey, className }) => (value, record, index) => {
  const cls = className ? className + " table-col" : " table-col";
  return <div key={itemKey + "_" + index}><MyUpload fileList={value} className={cls} listType={"picture-card"} preview={true} showUploadList={{showRemoveIcon:false}} disabled={true}/></div>
}

SfUpload.craft = {
  displayName: "Upload",
  related: {
    settings: SfUploadSetting
  }
}

SfUpload.validate = (props, {parents, container, extraParams}) => {
  if (container.type.resolvedName === "SfMainPanel") {
    extraParams.dataClassConfig.columns.push({
      dataIndex: props.itemKey,
      index: Object.keys(parents).indexOf(props.itemKey),
      type: "file[]",
      listType: props.listType,
      preview: props.preview,
      title: props.tableColTitle || props.title,
      width: props.tableColWidth,
      sortable: false,
      editable: false,
      permission: props.permission,
      volitate: props.volitate,
      hiddenForReadOnly: props.hideInTable,
    });
  }
}

SfUpload.isSearchable = true;
SfUpload.isRulable = true;

registerComponent({
  key: "SfUpload",
  component: SfUpload,
  runtimeComponent: SfpUpload,
  template: <SfUpload itemKey={getId('upload')} className="sf-upload wrap"
    title={"Upload"} span={24} labelColStr="span:6" defaultSharing={"public-image-protected-file"} />,
  title: <IntlMessages id="system.form.library.upload" text="Upload" />,
  icon: <UploadOutlined  className="react-icons icon-antd"/>,
  type: "Component",
  sequence: 17,
});

export default SfUpload;